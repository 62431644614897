  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/1-5-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li>Expertise technique batiment</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’expertise technique bâtiment</h1>

<p>L’expertise technique bâtiment vise à décrire les désordres sur un bâtiment (fissures, humidité…) et à évaluer leurs conséquences sur le bâtiment.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expertise technique batiment" />
</div>


<p>Elle fait intervenir un professionnel indépendant du bâtiment et, si besoin est, le concours d’un sapiteur. </p>

<p>Un sapiteur est un spécialiste qui intervient sur des problématiques spécifiques du bâtiment. Il peut s'agir d’un ingénieur structure ou béton armé.</p>
<h1>Quand réaliser une expertise technique bâtiment ?</h1>

<p>L’expertise technique bâtiment s’avère nécessaire lorsqu’un Maître d’ouvrage, un propriétaire ou un futur acquéreur se trouve confronté à des questionnements d’ordre technique sur l’état d’un ouvrage, des constats de désordres ou suite à un sinistre survenu sur un ouvrage.</p>

<p>L’expertise technique bâtiment s’avère ainsi nécessaire lorsque :</p>
<ul><li>un ouvrage présente des fissures,</li>
<li>des malfaçons de construction sont constatées,</li>
<li>un litige avec un constructeur ou une assurance.</li></ul>

<h2>Préconiser et chiffrer les solutions techniques de réparation</h2>

<p>L’expertise technique bâtiment vise à établir, suite à l’identification des causes et origines des dommages, des préconisations et un chiffrage des travaux de correction.</p>

<p>Attention, il s’agit ici d’un chiffrage estimatif et non définitif. L’expert technique bâtiment n’est pas tenu de donner un chiffrage précis afin de pas dévier vers de la Maîtrise d’Oeuvre.</p>



<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert bâtiment</a>
    </div>
</div>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/expertise-batiment/' className='active'>Expertise technique batiment</a></li><li><a href='/expertise-construction/expertise-batiment/malfacons/' >Malfaçon</a></li><li><a href='/expertise-construction/expertise-batiment/garanties-construction/' >Garanties de construction</a></li><li><a href='/expertise-construction/expertise-batiment/recours/' >Recours amiables et judicaires</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details